<template>
  <v-card
      class="mx-auto"
      width="374"
      max-height="800"
  >
    <v-card-title class="primary white--text">状态设置</v-card-title>
    <v-card-text class="pt-2">
      <v-row align="center" class="mt-0">
        <v-col cols="12">

          <v-select
              v-model="status"
              :items="statusList"
              attach
              label="权限"
              dense
              small-chips
              outlined
              item-value="code"
              item-text="name"
              return-object
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
          color="primary"
          text
          @click="save"
      >
        保存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "statusEditor",
  props: {
    node: {
      type: Object,
      default: () => {
      }
    },
    statusList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      status: {
      },
    }
  },
  methods: {
    save() {
      const node = {
        id: this.node.id,
        data: this.status
      }
      this.$emit('save', node)
    },
  },
  watch: {
    'node'(v) {
      this.status = {...v.data}
    }
  }
}
</script>

<style scoped>

</style>