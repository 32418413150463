<template>
  <div class="status">

    <div class="status-title">
      <!--    <span>{{ status.name }}</span>-->
      <span>{{ status.name }}</span>
    </div>
  </div>
</template>

<script>
import bus from "@/plugins/eventBus";

export default {
  name: "status",
  created() {
    bus.$on('SendData', (node) => {
      if (this.nodeId === node.id.toString()) {
        this.status = {...node.data}
      }
    })
  },
  mounted() {
    this.getNodeId()
  },
  data() {
    return {
      nodeId: 123,
      status: {
        name: '状态'
      }
    }
  },
  methods: {
    getNodeId() {
      this.$nextTick(() => {
        const id = this.$el.parentElement.parentElement.id;
        this.nodeId = id.split('-')[1];
        bus.$emit('getData', this.nodeId);
      });
    }
  },
  computed: {}
}
</script>

<style scoped>
.status {
  min-width: 96px;
  height: 32px;
  display: flex;
  flex-direction: column;
  background-color: goldenrod;
}

.status-title {
  color: white;
  text-align: center;
  margin: auto 10px;
}
</style>