<template>
  <div class="wf-container borderd" v-on:keyup.tab="key_tab">
    <div id="drawflow" class=" elevation-4" @drop="drop" @dragenter.prevent @dragover.prevent/>
    <div class="tool-bar">
      <v-text-field v-model="selectedNode.id" label="Solo" solo dense></v-text-field>
      <v-btn color="success darken-1" @click="setWorkflow">保存</v-btn>
      <v-btn color="warning darken-1" @click="reset">reset</v-btn>
      <v-btn color="primary" @click="exportData">console</v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ }">
          <v-btn fab small draggable @dragstart="dragStart('status',$event)">
            <v-icon>mdi-tag-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Tooltip</span>
      </v-tooltip>
      <v-btn fab small draggable @dragstart="dragStart('transition',$event)">
        <v-icon>mdi-strategy</v-icon>
      </v-btn>
      <div style="width: 250px">
        <v-slider
            max="160"
            min="40"
            step="1"
            dense
            thumb-size="24"
            thumb-label
            @input="zoom"
            :value="zoom_value"
        >
          <template #prepend>
            <v-icon
                :color="'primary'"
                @click="zoom(zoom_value-=1)"
            >
              mdi-minus
            </v-icon>
          </template>
          <template #append>
            <v-icon
                :color="'primary'"
                @click="zoom(zoom_value+=1)"
            >
              mdi-plus
            </v-icon>
          </template>
        </v-slider>
      </div>
    </div>
    <transition name="slide-fade">
      <div class="property-card "
           v-show="selectedNode.id"
      >
        <transition-editor v-show="selectedNode.name === 'transition'" :node="selectedNode"
                           @save="updateNode"></transition-editor>
        <status-editor v-show="selectedNode.name === 'status'" :node="selectedNode"
                       @save="updateNode"
                       :status-list="statusList"></status-editor>
      </div>
    </transition>
  </div>
</template>

<script>
import Drawflow from 'drawflow'
import 'drawflow/dist/drawflow.min.css'
import transitionNode from "@/components/workflow/transitionNode";
import statusNode from "@/components/workflow/statusNode";
import statusEditor from "@/components/workflow/statusEditor";
import transitionEditor from "@/components/workflow/transitionEditor";
import bus from "@/plugins/eventBus";
import workflowEditor from "@/components/workflow/workflowEditor";
import Vue from 'vue'
// import cloudbase from "@/plugins/cloudbase";



export default {
  name: "index",
  components: {statusEditor, transitionEditor, workflowEditor},
  data() {
    return {
      style: {
        container: {
          height: 1060
        }
      },
      projectId: '0a4ec1f962cb02b30fad17ac648e9bc5',
      name: '工作流的名字',
      editor: null,
      workflow: {
        "Home": {
          "data": {
            "1": {
              "id": 1,
              "name": "transition",
              "data": {
                "groups": [{
                  "name": "客户信息",
                  "columns": [{"name": "客户姓名", "editable": false}, {"name": "联系电话", "editable": false}, {
                    "name": "地址",
                    "editable": false
                  }, {"name": "故障类型", "editable": false}, {
                    "name": "Gingerbread",
                    "editable": false
                  }, {"name": "Jelly bean", "editable": false}, {
                    "name": "Lollipop",
                    "editable": false
                  }, {"name": "Honeycomb", "editable": false}, {"name": "Donut", "editable": false}, {
                    "name": "KitKat",
                    "editable": false
                  }]
                }, {
                  "name": "故障",
                  "columns": [{"name": "Frozen Yogurt", "editable": false}, {
                    "name": "Ice cream sandwich",
                    "editable": false
                  }, {"name": "Eclair", "editable": false}, {
                    "name": "Cupcake",
                    "editable": false
                  }, {"name": "Gingerbread", "editable": false}, {
                    "name": "Jelly bean",
                    "editable": false
                  }, {"name": "Lollipop", "editable": false}, {
                    "name": "Honeycomb",
                    "editable": false
                  }, {"name": "Donut", "editable": false}, {"name": "KitKat", "editable": false}]
                }, {
                  "name": "订单信息",
                  "columns": [{"name": "Frozen Yogurt", "editable": false}, {
                    "name": "Ice cream sandwich",
                    "editable": false
                  }, {"name": "Eclair", "editable": false}, {
                    "name": "Cupcake",
                    "editable": false
                  }, {"name": "Gingerbread", "editable": false}, {
                    "name": "Jelly bean",
                    "editable": false
                  }, {"name": "Lollipop", "editable": false}, {
                    "name": "Honeycomb",
                    "editable": false
                  }, {"name": "Donut", "editable": false}, {"name": "KitKat", "editable": false}]
                }, {
                  "name": "赔付",
                  "columns": [{"name": "Frozen Yogurt", "editable": false}, {
                    "name": "Ice cream sandwich",
                    "editable": false
                  }, {"name": "Eclair", "editable": false}, {
                    "name": "Cupcake",
                    "editable": false
                  }, {"name": "Gingerbread", "editable": false}, {
                    "name": "Jelly bean",
                    "editable": false
                  }, {"name": "Lollipop", "editable": false}, {
                    "name": "Honeycomb",
                    "editable": false
                  }, {"name": "Donut", "editable": false}, {"name": "KitKat", "editable": false}]
                }], "name": "创建"
              },
              "class": "transition",
              "html": "transition",
              "typenode": "vue",
              "inputs": {},
              "outputs": {"output_1": {"connections": []}},
              "pos_x": 83,
              "pos_y": 398
            }
          }
        }
      },
      editorShow: false,
      selectedNode: {},
      position_y: 0,
      winheight: 0,
      rect: {},
      zoom_value: 100,
    }
  },
  mounted() {
    this.getWorkflow()
    this.initEditor()
  },
  methods: {
    getWorkflow() {
      let data = JSON.parse(window.localStorage.getItem('workflow'))
      if (data) {
        this.workflow.Home = data
      }
    },
    setWorkflow() {
      const workflow = this.editor.export().drawflow.Home
      window.localStorage.setItem('workflow', JSON.stringify(workflow))
    },
    reset() {
      window.localStorage.removeItem('workflow')
    },
    initEditor() {
      const drawflow = document.getElementById("drawflow");
      this.editor = new Drawflow(drawflow, Vue, this);
      this.editor.reroute = true;
      this.editor.registerNode('start', transitionNode, {})
      this.editor.registerNode('status', statusNode, {})
      this.editor.registerNode('transition', transitionNode, {})
      this.editor.start();
      this.editor.import({drawflow: this.workflow})


      this.editor.on('nodeSelected', this.selectNode)
      this.editor.on('nodeUnselected', () => {
        this.selectedNode = {}
      })

      this.editor.on('zoom', (v) => {
        this.zoom_value = v * 100
      })
      bus.$on('getData', this.sendData)
    },
    sendData(nodeId){
      const node = this.editor.getNodeFromId(nodeId);
      bus.$emit('SendData', node)
    },
    selectNode(nodeId) {
      this.selectedNode = this.editor.getNodeFromId(nodeId)
      console.log('selectNode', nodeId,this.selectedNode, this.selectedNode.name,this.selectedNode.data)

    },
    dragStart(type, event) {
      event.dataTransfer.setData('nodeType', type);
    },
    updateNode({id,data}) {
      console.log('updateNode', {id,data})
      this.editor.updateNodeDataFromId(id, data)
      this.sendData(id)
      this.workflow = this.editor.export()

    },
    drop(event) {
      const nodeType = event.dataTransfer.getData("nodeType")
      const {clientX,clientY} = event
      this.addNode({clientX, clientY}, nodeType)
    },
    addNode({clientX, clientY}, type) {
      const data = {}
      clientX = clientX * ( this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().x * ( this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)));
      clientY = clientY * ( this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().y * ( this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)));
      this.editor.addNode(type, 1, 1, clientX, clientY, type, data, type, 'vue');
    },
    key_tab() {
      if (this.selectedNode) {
        console.log(this.selectedNode)
      }
    },
    exportData() {
      this.workflow = this.editor.export()
      console.log(this.workflow)
    },
    zoom(v) {
      this.zoom_value = v
      this.editor.zoom = v / 100
      this.editor.zoom_refresh()
    }

  },
  computed: {
    statusList() {
      return this.$store.state.projectSettings.find(s => {
        return s._id === this.projectId
      })?.status || []
    },

  },
  watch: {}
}
</script>

<style scoped>
#drawflow {
  width: 100%;
  height: calc(100vh - 150px);
}

.name-input {

}

.borderd {
  border: 1px solid red;
}

.wf-container {
  position: relative;

}

.tool-bar {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-flow: row;
  gap: 16px;
  align-items: start;
}

.property-card {
  position: absolute;
  top: 20px;
  right: 20px;
  border: red solid 1px;
}

.slide-fade-enter-active {
  transition: all .1s ease;
}

.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */
{
  transform: translateX(100px);
  opacity: 0;
}


</style>

<style>

:root {
  --dfBackgroundColor: rgba(255, 255, 255, 1);
  --dfBackgroundSize: 16px;
  --dfBackgroundImage: linear-gradient(to right, rgba(237, 227, 227, 1) 1px, transparent 1px), linear-gradient(to bottom, rgba(237, 227, 227, 1) 1px, transparent 1px);

  --dfNodeType: flex;
  --dfNodeTypeFloat: none;
  --dfNodeBackgroundColor: #ffffff;
  --dfNodeTextColor: #000000;
  --dfNodeBorderSize: 2px;
  --dfNodeBorderColor: #000000;
  --dfNodeBorderRadius: 4px;
  --dfNodeMinHeight: 10px;
  --dfNodeMinWidth: 100px;
  --dfNodePaddingTop: 4px;
  --dfNodePaddingBottom: 4px;
  --dfNodeBoxShadowHL: 0px;
  --dfNodeBoxShadowVL: 2px;
  --dfNodeBoxShadowBR: 15px;
  --dfNodeBoxShadowS: 2px;
  --dfNodeBoxShadowColor: #000000;

  --dfNodeHoverBackgroundColor: #ffffff;
  --dfNodeHoverTextColor: #000000;
  --dfNodeHoverBorderSize: 2px;
  --dfNodeHoverBorderColor: #000000;
  --dfNodeHoverBorderRadius: 4px;

  --dfNodeHoverBoxShadowHL: 0px;
  --dfNodeHoverBoxShadowVL: 2px;
  --dfNodeHoverBoxShadowBR: 15px;
  --dfNodeHoverBoxShadowS: 2px;
  --dfNodeHoverBoxShadowColor: #4ea9ff;

  --dfNodeSelectedBackgroundColor: rgba(255, 255, 255, 1);
  --dfNodeSelectedTextColor: rgba(0, 0, 0, 1);
  --dfNodeSelectedBorderSize: 2px;
  --dfNodeSelectedBorderColor: #000000;
  --dfNodeSelectedBorderRadius: 4px;

  --dfNodeSelectedBoxShadowHL: 0px;
  --dfNodeSelectedBoxShadowVL: 2px;
  --dfNodeSelectedBoxShadowBR: 15px;
  --dfNodeSelectedBoxShadowS: 2px;
  --dfNodeSelectedBoxShadowColor: #4ea9ff;

  --dfInputBackgroundColor: #ffffff;
  --dfInputBorderSize: 2px;
  --dfInputBorderColor: #000000;
  --dfInputBorderRadius: 50px;
  --dfInputLeft: -8px;
  --dfInputHeight: 16px;
  --dfInputWidth: 16px;

  --dfInputHoverBackgroundColor: #ffffff;
  --dfInputHoverBorderSize: 2px;
  --dfInputHoverBorderColor: #000000;
  --dfInputHoverBorderRadius: 50px;

  --dfOutputBackgroundColor: #ffffff;
  --dfOutputBorderSize: 2px;
  --dfOutputBorderColor: #000000;
  --dfOutputBorderRadius: 50px;
  --dfOutputRight: 8px;
  --dfOutputHeight: 16px;
  --dfOutputWidth: 16px;

  --dfOutputHoverBackgroundColor: #ffffff;
  --dfOutputHoverBorderSize: 2px;
  --dfOutputHoverBorderColor: #000000;
  --dfOutputHoverBorderRadius: 50px;

  --dfLineWidth: 4px;
  --dfLineColor: #4682b4;
  --dfLineHoverColor: rgba(178, 186, 30, 1);
  --dfLineSelectedColor: #43b993;

  --dfRerouteBorderWidth: 2px;
  --dfRerouteBorderColor: #000000;
  --dfRerouteBackgroundColor: #ffffff;

  --dfRerouteHoverBorderWidth: 2px;
  --dfRerouteHoverBorderColor: #000000;
  --dfRerouteHoverBackgroundColor: #ffffff;

  --dfDeleteDisplay: block;
  --dfDeleteColor: #ffffff;
  --dfDeleteBackgroundColor: #000000;
  --dfDeleteBorderSize: 2px;
  --dfDeleteBorderColor: #ffffff;
  --dfDeleteBorderRadius: 50px;
  --dfDeleteTop: -15px;

  --dfDeleteHoverColor: #000000;
  --dfDeleteHoverBackgroundColor: #ffffff;
  --dfDeleteHoverBorderSize: 2px;
  --dfDeleteHoverBorderColor: #000000;
  --dfDeleteHoverBorderRadius: 50px;

}

#drawflow {
  background: var(--dfBackgroundColor);
  background-size: var(--dfBackgroundSize) var(--dfBackgroundSize);
  background-image: var(--dfBackgroundImage);
}


.status {
  border-radius: 40px !important;
}

.transition {
}

.drawflow .drawflow-node {
  display: var(--dfNodeType);
  padding: 0;
  background: var(--dfNodeBackgroundColor);
  color: var(--dfNodeTextColor);
  border: var(--dfNodeBorderSize) solid var(--dfNodeBorderColor);
  border-radius: var(--dfNodeBorderRadius);
  min-height: var(--dfNodeMinHeight);
  width: auto;
  min-width: var(--dfNodeMinWidth);
  /*padding-top: var(--dfNodePaddingTop);*/
  /*padding-bottom: var(--dfNodePaddingBottom);*/
  -webkit-box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS) var(--dfNodeBoxShadowColor);
  box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS) var(--dfNodeBoxShadowColor);
}

.drawflow .drawflow-node.round {
  background: red;
}

.drawflow .drawflow-node:hover {
  background: var(--dfNodeHoverBackgroundColor);
  color: var(--dfNodeHoverTextColor);
  border: var(--dfNodeHoverBorderSize) solid var(--dfNodeHoverBorderColor);
  border-radius: var(--dfNodeHoverBorderRadius);
  -webkit-box-shadow: var(--dfNodeHoverBoxShadowHL) var(--dfNodeHoverBoxShadowVL) var(--dfNodeHoverBoxShadowBR) var(--dfNodeHoverBoxShadowS) var(--dfNodeHoverBoxShadowColor);
  box-shadow: var(--dfNodeHoverBoxShadowHL) var(--dfNodeHoverBoxShadowVL) var(--dfNodeHoverBoxShadowBR) var(--dfNodeHoverBoxShadowS) var(--dfNodeHoverBoxShadowColor);
}

.drawflow .drawflow-node.selected {
  background: var(--dfNodeSelectedBackgroundColor);
  color: var(--dfNodeSelectedTextColor);
  border: var(--dfNodeSelectedBorderSize) solid var(--dfNodeSelectedBorderColor);
  border-radius: var(--dfNodeSelectedBorderRadius);
  -webkit-box-shadow: var(--dfNodeSelectedBoxShadowHL) var(--dfNodeSelectedBoxShadowVL) var(--dfNodeSelectedBoxShadowBR) var(--dfNodeSelectedBoxShadowS) var(--dfNodeSelectedBoxShadowColor);
  box-shadow: var(--dfNodeSelectedBoxShadowHL) var(--dfNodeSelectedBoxShadowVL) var(--dfNodeSelectedBoxShadowBR) var(--dfNodeSelectedBoxShadowS) var(--dfNodeSelectedBoxShadowColor);
}

.drawflow .drawflow-node .input {
  left: var(--dfInputLeft);
  background: var(--dfInputBackgroundColor);
  border: var(--dfInputBorderSize) solid var(--dfInputBorderColor);
  border-radius: var(--dfInputBorderRadius);
  height: var(--dfInputHeight);
  width: var(--dfInputWidth);
}

.drawflow .drawflow-node .input:hover {
  background: var(--dfInputHoverBackgroundColor);
  border: var(--dfInputHoverBorderSize) solid var(--dfInputHoverBorderColor);
  border-radius: var(--dfInputHoverBorderRadius);
}


.drawflow .drawflow-node .outputs {
  float: var(--dfNodeTypeFloat);
}

.drawflow .drawflow-node .output {
  right: var(--dfOutputRight);
  background: var(--dfOutputBackgroundColor);
  border: var(--dfOutputBorderSize) solid var(--dfOutputBorderColor);
  border-radius: var(--dfOutputBorderRadius);
  height: var(--dfOutputHeight);
  width: var(--dfOutputWidth);
}

.drawflow .drawflow-node .output:hover {
  background: var(--dfOutputHoverBackgroundColor);
  border: var(--dfOutputHoverBorderSize) solid var(--dfOutputHoverBorderColor);
  border-radius: var(--dfOutputHoverBorderRadius);
}

.drawflow .connection .main-path {
  stroke-width: var(--dfLineWidth);
  stroke: var(--dfLineColor);
}

.drawflow .connection .main-path:hover {
  stroke: var(--dfLineHoverColor);
}

.drawflow .connection .main-path.selected {
  stroke: var(--dfLineSelectedColor);
}

.drawflow .connection .point {
  stroke: var(--dfRerouteBorderColor);
  stroke-width: var(--dfRerouteBorderWidth);
  fill: var(--dfRerouteBackgroundColor);
}

.drawflow .connection .point:hover {
  stroke: var(--dfRerouteHoverBorderColor);
  stroke-width: var(--dfRerouteHoverBorderWidth);
  fill: var(--dfRerouteHoverBackgroundColor);
}

.drawflow-delete {
  display: var(--dfDeleteDisplay);
  color: var(--dfDeleteColor);
  background: var(--dfDeleteBackgroundColor);
  border: var(--dfDeleteBorderSize) solid var(--dfDeleteBorderColor);
  border-radius: var(--dfDeleteBorderRadius);
}

.parent-node .drawflow-delete {
  top: var(--dfDeleteTop);
}

.drawflow-delete:hover {
  color: var(--dfDeleteHoverColor);
  background: var(--dfDeleteHoverBackgroundColor);
  border: var(--dfDeleteHoverBorderSize) solid var(--dfDeleteHoverBorderColor);
  border-radius: var(--dfDeleteHoverBorderRadius);
}


</style>