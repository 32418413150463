<template>
  <div>state editor</div>
</template>

<script>
export default {
  name: "statusEditor",
  props: {
    status: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style scoped>

</style>