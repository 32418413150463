<template>
  <v-card
      class="mx-auto"
      width="374"
      max-height="800"
  >
    <v-card-title class="primary white--text">动作设置</v-card-title>
    <v-card-text class="pt-2">
      <div class=" text-subtitle-2">字段设置</div>
      <v-row align="center" class="mt-0">
        <v-col cols="12">
          <v-text-field
              v-model="transition.name"
              hint="工单操作按钮名称"
              label="名称"
              dense
              outlined
          ></v-text-field>
          <v-select
              v-model="transition.permissions"
              :items="['管理','坐席','服务商','工程师']"
              attach
              label="权限"
              multiple
              dense
              small-chips
              outlined
          ></v-select>
          <v-text-field
              v-model="transition.hint"
              label="按钮提示"
              dense
              outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="py-0 flex-grow-1 flex-shrink-1">
      <div class="text-subtitle-2">字段设置</div>
      <v-simple-table height="400" fixed-header dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              字段
            </th>
            <th class="text-left">
              可编辑
            </th>
            <th class="text-left">
              必填
            </th>
          </tr>
          </thead>
          <tbody v-for="group in transition.groups" :key="group.name">
          <div class="subtitle-2 mt-2">{{ group.name }}</div>
          <tr v-for="column in group.columns" :key="column.name">
            <td>{{ column.name }}</td>
            <td>
              <v-simple-checkbox v-ripple="false" color="primary" v-model="column.editable"></v-simple-checkbox>
            </td>
            <td>
              <v-simple-checkbox v-ripple="false" color="primary" :disabled="!column.editable"
                                 v-model="column.required"></v-simple-checkbox>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-btn
          color="primary"
          text
          @click="save"
      >
        保存
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "transitionEditor",
  props: {
    node: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      transition: {
        name: '动作',
        permissions: [],
        hint: '',
        groups: [
          {
            name: '客户信息',
            columns: [
              {
                name: '客户姓名',
                editable: false,
              },
              {
                name: '联系电话',
                editable: false,
              },
              {
                name: '地址',
                editable: false,
              },
              {
                name: '故障类型',
                editable: false,
              },
              {
                name: 'Gingerbread',
                editable: false,
              },
              {
                name: 'Jelly bean',
                editable: false,
              },
              {
                name: 'Lollipop',
                editable: false,
              },
              {
                name: 'Honeycomb',
                editable: false,
              },
              {
                name: 'Donut',
                editable: false,
              },
              {
                name: 'KitKat',
                editable: false,
              },
            ]
          },
          {
            name: '故障',
            columns: [
              {
                name: 'Frozen Yogurt',
                editable: false,
              },
              {
                name: 'Ice cream sandwich',
                editable: false,
              },
              {
                name: 'Eclair',
                editable: false,
              },
              {
                name: 'Cupcake',
                editable: false,
              },
              {
                name: 'Gingerbread',
                editable: false,
              },
              {
                name: 'Jelly bean',
                editable: false,
              },
              {
                name: 'Lollipop',
                editable: false,
              },
              {
                name: 'Honeycomb',
                editable: false,
              },
              {
                name: 'Donut',
                editable: false,
              },
              {
                name: 'KitKat',
                editable: false,
              },
            ]
          },
          {
            name: '订单信息',
            columns: [
              {
                name: 'Frozen Yogurt',
                editable: false,
              },
              {
                name: 'Ice cream sandwich',
                editable: false,
              },
              {
                name: 'Eclair',
                editable: false,
              },
              {
                name: 'Cupcake',
                editable: false,
              },
              {
                name: 'Gingerbread',
                editable: false,
              },
              {
                name: 'Jelly bean',
                editable: false,
              },
              {
                name: 'Lollipop',
                editable: false,
              },
              {
                name: 'Honeycomb',
                editable: false,
              },
              {
                name: 'Donut',
                editable: false,
              },
              {
                name: 'KitKat',
                editable: false,
              },
            ]
          },
          {
            name: '赔付',
            columns: [
              {
                name: 'Frozen Yogurt',
                editable: false,
              },
              {
                name: 'Ice cream sandwich',
                editable: false,
              },
              {
                name: 'Eclair',
                editable: false,
              },
              {
                name: 'Cupcake',
                editable: false,
              },
              {
                name: 'Gingerbread',
                editable: false,
              },
              {
                name: 'Jelly bean',
                editable: false,
              },
              {
                name: 'Lollipop',
                editable: false,
              },
              {
                name: 'Honeycomb',
                editable: false,
              },
              {
                name: 'Donut',
                editable: false,
              },
              {
                name: 'KitKat',
                editable: false,
              },
            ]
          },
        ],
      },
    }
  },
  methods: {
    save() {
      const node = {
        id: this.node.id,
        data: this.transition
      }
      this.$emit('save', node)
    },
    setTransitionGroups(groups) {
      let defaultGroups = [
        {
          name: '客户信息',
          columns: [
            {
              name: '客户姓名',
              editable: false,
            },
            {
              name: '联系电话',
              editable: false,
            },
            {
              name: '地址',
              editable: false,
            },
            {
              name: '故障类型',
              editable: false,
            },
            {
              name: 'Gingerbread',
              editable: false,
            },
            {
              name: 'Jelly bean',
              editable: false,
            },
            {
              name: 'Lollipop',
              editable: false,
            },
            {
              name: 'Honeycomb',
              editable: false,
            },
            {
              name: 'Donut',
              editable: false,
            },
            {
              name: 'KitKat',
              editable: false,
            },
          ]
        },
        {
          name: '故障',
          columns: [
            {
              name: 'Frozen Yogurt',
              editable: false,
            },
            {
              name: 'Ice cream sandwich',
              editable: false,
            },
            {
              name: 'Eclair',
              editable: false,
            },
            {
              name: 'Cupcake',
              editable: false,
            },
            {
              name: 'Gingerbread',
              editable: false,
            },
            {
              name: 'Jelly bean',
              editable: false,
            },
            {
              name: 'Lollipop',
              editable: false,
            },
            {
              name: 'Honeycomb',
              editable: false,
            },
            {
              name: 'Donut',
              editable: false,
            },
            {
              name: 'KitKat',
              editable: false,
            },
          ]
        },
        {
          name: '订单信息',
          columns: [
            {
              name: 'Frozen Yogurt',
              editable: false,
            },
            {
              name: 'Ice cream sandwich',
              editable: false,
            },
            {
              name: 'Eclair',
              editable: false,
            },
            {
              name: 'Cupcake',
              editable: false,
            },
            {
              name: 'Gingerbread',
              editable: false,
            },
            {
              name: 'Jelly bean',
              editable: false,
            },
            {
              name: 'Lollipop',
              editable: false,
            },
            {
              name: 'Honeycomb',
              editable: false,
            },
            {
              name: 'Donut',
              editable: false,
            },
            {
              name: 'KitKat',
              editable: false,
            },
          ]
        },
        {
          name: '赔付',
          columns: [
            {
              name: 'Frozen Yogurt',
              editable: false,
            },
            {
              name: 'Ice cream sandwich',
              editable: false,
            },
            {
              name: 'Eclair',
              editable: false,
            },
            {
              name: 'Cupcake',
              editable: false,
            },
            {
              name: 'Gingerbread',
              editable: false,
            },
            {
              name: 'Jelly bean',
              editable: false,
            },
            {
              name: 'Lollipop',
              editable: false,
            },
            {
              name: 'Honeycomb',
              editable: false,
            },
            {
              name: 'Donut',
              editable: false,
            },
            {
              name: 'KitKat',
              editable: false,
            },
          ]
        },
      ]
      groups?.forEach(group => {
        let defaultGroupIndex = defaultGroups.findIndex(s => {
          return s.name === group.name
        })

        if (defaultGroupIndex !== undefined) {
          let defaultGroup = defaultGroups[defaultGroupIndex]
          group.columns?.forEach(column => {
            let defaultColumnIndex = defaultGroup.columns.findIndex(s => {
              return s.name === column.name
            })
            if (defaultColumnIndex !== undefined) {
              defaultGroup.columns[defaultColumnIndex] = {...column}
            }
          })
        }
      })
      this.$set(this.transition, 'groups', defaultGroups)
    }
  },
  watch: {
    'node'(v) {
      this.transition = {...v.data}
      this.setTransitionGroups(v.data?.groups)
    }
  }
}
</script>

<style scoped>

</style>