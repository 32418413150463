<template>
  <div class="transition">
    <div class="transition-title font-weight-medium">{{ transition.name }}</div>
  </div>
</template>

<script>
import bus from "@/plugins/eventBus";

export default {
  name: "status",
  created() {
    bus.$on('SendData', (node) => {
      if (this.nodeId === node.id.toString()) {
        this.transition = {...this.transition, ...node.data}
      }
    })
  },
  mounted() {
    this.getNodeId()
  },
  data() {
    return {
      nodeId: null,
      transition: {
        name: '点击设置名称',
        permissions: []
      }
    }
  },
  methods: {
    getNodeId() {
      this.$nextTick(() => {
        const id = this.$el.parentElement.parentElement.id;
        this.nodeId = id.split('-')[1];
        bus.$emit('getData', this.nodeId);
      });
    }
  },
  computed: {}
}
</script>

<style scoped>
.transition {
  width: 128px;
  height: 32px;
  display: flex;
  flex-direction: column;
  background-color: steelblue;
}

.transition-title {
  color: white;
  text-align: center;
  margin: auto 10px;
}
</style>